import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const Education = () => (
  <Layout>
    <SEO
      title="Natural Hair Education"
      desciption="Natural hair care should start with an understanding. There are endless tricks and tips for looking after your natural hair and everywhere you turn, this guide will point you in the right direction"
    />
    <h1>Natural Hair Education</h1>
    <h2>Having a deeper understaning of how natural hair works and what it's made of can help you get the beautiful hair you want.</h2>

    <p>There are endless tricks and tips for looking after your natural hair and everywhere you turn, it can seem like everyone has their own opinion on what you should do to get the best results. Natural hair care should start with an understanding. If you blindly follow advice without understanding why, you can end up more confused than when you first started.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/RxUrHRW.jpg"
        alt="natural hair education"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@urusy"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Hiroyoshi Urushima</a></p>
    </div>

    <h2>Understanding Why</h2>
    <p>When you know why you are doing something, you can make the slight changes it might take for that method to work for you. The advice you are getting could be perfect for you in every way except for that one piece that is wrong or missing. And unfortunately that final piece is what you need to make it work.</p>
    <p>If you get your information online it’s even worse because it’s all a one way conversation. They speak about  hair care based on their own experiences and hair type. If your hair type is different, they have no way of telling. That’s something you will have to judge for yourself.</p>
    <p>Understanding why you should add oil, why you should add conditioner, why you should keep your hair wrapped at night will make all the difference. It will feel like less of a chore and more like something that is necessary to get you to your goals.</p>

    <h2>Read Books</h2>
    <p>If you like reading books then this could be a great option for you. Some great minds have taken the time to arrange the knowledge they have built up and put it on paper so everyone can access it. It’s amazing if you really think about it and not just for hair care. For nearly every field out there, some expert has written a book to pass on their knowledge.</p>
    <p>Obviously not all books are created equal and some are easier to take in than others because of the level of writing. To get around this you could read a few reviews to see what other people thought. The danger is that we are not all the same and what someone else likes, you might not. Also the other way around where you might love something someone else hated.</p>
    <p>When a lot of people think about books it’s usually a hard back we think of first. The modern age has given us lots of options beyond this to the point where you can download a complete book on your phone as a free pdf if you’re lucky. This has also made us less appreciative because when everything is at your fingertips, it makes the likelihood of finishing the book you started much less.</p>
    <p>A starting point would be to search for a piece of knowledge you think you need to learn more about and see if you can find a book that has the subject you are after, it’s a bonus if it’s by a notable author and with a few reviews.</p>
    <p>There is a lot to be learned from books and a little patience and some dedication will take you far in the long term. In the short term, you will pick up some tips you can take action on now and get started on the journey to fully understanding your own hair.</p>

    <h2>Watch Documentaries</h2>
    <p>If you haven’t got the patience to sit and read a book over a few days or weeks then watching documentaries is a great way to learn. There are different categories you can look for from the manufacturing of various products to the actual make-up of hair itself.</p>
    <p>I watched a documentary on shea butter recently. I learned about the different types so why there are different colours, where and how it’s made, how long it takes and a whole bunch more. After learning about it, I got some ideas about how it can be used and everything became so much more clear. Instead of listening to the opinions of people on forums who are only guessing and pushing their opinion as fact, I went straight to the source and feel so much better for it.</p>
    <p>As for where to find documentaries, you can browse the internet to see what you can find. Ask around in your circle to see if anyone has watched anything interesting. You might even get a surprise and find that someone you are near to has made their own documentary or filmed their own research. One of the best places to find a good documentary is YouTube, there are lots of good options and most are free, you just have to know where to look.</p>

    <h2>Watch Hair Care YouTubers</h2>
    <p>Watching YouTubers is another great way to learn. You might think a lot of influencers are all about entertainment and selling products but some of the best know exactly what they are talking about when it comes to hair. That’s probably how they got their following in the first place, after all, people on social media are quick to point out mistakes and lies.</p>
    <p>The thing with YouTubers is that there are so many of them that if you don’t like the first one you find, you can go and search out another one. Something that I always recommend is not just watching for watching sake. Find someone with hair as similar to your own as you can and use them as a “hair twin”. If their hair looks and behaves just like yours does, you can learn from their experiences to get what you want.</p>
    <p>A lot of them do full length tutorials where they take you through the products they use from start to finish. Often showing you the washing process, combing and applying all the product. I like these kinds of reviews because you learn a lot about how the product works without having to spend money on it yourself. Now, the best thing to do is to try something yourself and see how you get on but the next best thing is to watch someone else do it and learn from them.</p>
    <p>Lastly, some YouTubers will make mini documentaries where they do their own research and put together something to educate their followers on all sorts of topics. It might be on the history of hair, all the different styles and where they come from or anything else they can think of at the time. The good thing is that you can usually interact with them in the comments and if they feel it’s worth it, they might just answer you and use your idea for a future video.</p>

    <h2>Conclusion</h2>
    <p>As you can see, education can be had in all forms and it doesn’t mean sitting in front of a teacher in a formal setting. It’s usually just taking some time out or your day to really understand something that interests you.</p>
    <p>In this case we are talking about natural hair care because that’s what the site is all about but this advice applies to everything in life. If you want to get better at something or get more knowledgeable on the topic then using your imagination to figure out the best way to get educated can do a lot for you.</p>
    <p>With regards to hair care, the benefits are amazing. Being educated about it means you can be more independent in your choices when it comes to general decisions and product choices. Instead of having to check a review every time and relying on someone else’s opinion, you can have confidence in your own.</p>
    <p>You can read the ingredients list and see if the product was made by someone who understands hair and wants to make a difference or just another company that wants to take advantage of the natural hair community and sell something that not only doesn’t work but also causes damage.</p>
    <p>It should be a positive experience and a long lasting journey where you pick up bits of information that will help you out. I write articles for this site and I’m constantly learning all the time too. It’s nothing to be ashamed of so ask questions when you are stuck and always seek to learn more. Then you can teach your friends, family, people in the natural hair community or even your own followers.</p>


    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>             
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default Education
